<template>
  <div id="settledResult">
    <div class="success-box" v-if="type == 1">
      <img src="@images/success.png" alt />
      <p class="title">申请成功</p>
      <p class="tips">入驻申请已通过</p>
      <p class="tips">账户及密码会以短信及微信通知发送</p>
      <p class="tips">快到PC端上传商品吧</p>
    </div>

    <div class="fail-box" v-if="type == 2">
      <img src="@images/y-fail.png" alt />
      <p class="title">申请失败</p>
      <p class="tips">{{reason}}</p>
      <div class="again-btn" @click="$router.push({path: '/storeSettled', query: {again: 1}})">重新申请</div>
    </div>

    <div class="examine-box" v-if="type == 3">
      <img src="@images/clock.png" alt />
      <p class="title">正在审核中</p>
      <p class="tips">我们将在1到3个工作日内给到您审核结果</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      type: "",
      reason: ""
    };
  },
  created() {},
  mounted() {
    this.type = this.$route.query.type;
    if (this.$route.query.reason) {
      this.reason = this.$route.query.reason;
    }
  },
  watch: {},
  computed: {},
  methods: {}
};
</script>
<style lang="scss" >
 @import '../common/css/theme.scss';
#settledResult {
  min-height: 100vh;
  background: rgba(245, 247, 250, 1);
  .success-box {
    padding-top: 6.4* 3.75px;
    box-sizing: border-box;
    height: 50.93* 3.75px;
    width: 100%;
    background: rgba(255, 255, 255, 1);
    img {
      margin: 0 auto;
      display: block;
      height: 16* 3.75px;
      width: 16* 3.75px;
    }

    .title {
      color: rgba(96, 98, 102, 1);
      font-size: 3.73* 3.75px;
      text-align: center;
      margin: 1.6* 3.75px 0;
    }
    .tips {
      font-size: 3.2* 3.75px;
      line-height: 4.53* 3.75px;
      color: rgba(144, 147, 153, 1);
    }
  }

  .fail-box {
    padding-top: 6.4* 3.75px;
    box-sizing: border-box;
    height: 56* 3.75px;
    background: rgba(255, 255, 255, 1);
    img {
      height: 16* 3.75px;
      width: 16* 3.75px;
    }

    .title {
      color: rgba(96, 98, 102, 1);
      font-size: 3.73* 3.75px;
      text-align: center;
      margin: 1.6* 3.75px 0;
    }
    .tips {
      font-size: 3.2* 3.75px;
      line-height: 4.53* 3.75px;
      color: rgba(144, 147, 153, 1);
    }
    .again-btn {
      width: 56* 3.75px;
      height: 8* 3.75px;
      background: $theme-color;
      border-radius: 1* 3.75px;
      font-size: 3.73* 3.75px;
      text-align: center;
      margin: 3.2* 3.75px auto 0;
      line-height: 8* 3.75px;
      color: rgba(255, 255, 255, 1);
    }
  }

  .examine-box {
    height: 100%;
    width: 100%;
    padding-top: 65.87* 3.75px;
    box-sizing: border-box;
    img {
      display: block;
      margin: 0 auto 2.13* 3.75px;
      height: 16* 3.75px;
      width: 16* 3.75px;
    }
    .title {
      color: rgba(96, 98, 102, 1);
      font-size: 3.73* 3.75px;
      text-align: center;
      margin: 1.6* 3.75px 0;
    }
    .tips {
      font-size: 3.2* 3.75px;
      line-height: 4.53* 3.75px;
      color: rgba(144, 147, 153, 1);
    }
  }
}
</style>